import './src/styles/global.css';

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout t. 
    if (location.pathname.includes("blog")) {
      return false;
    }
    const currentPosition = getSavedScrollPosition(location);
    return currentPosition || [0, 0];
  };
